import React from 'react';

export default function Spinner({ inline, overlayColor, overlayOpacity }) {
  return (
    <>
      {inline ? (
        <div className="inline-spinner-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div id="spinner-section">
          <div className="overlay" style={{ backgroundColor: overlayColor, opacity: overlayOpacity }}>
            <div className="spinner"></div>
          </div>
        </div>
      )}
    </>
  )
}

/* ---------------------------------
  Simple Lazy Loading Images

  Load in images with the ".lazy" class in when they come into
  view using the IntersectionObserver API

  Also functionality for lazy loading CSS background images as well

  NOTE: Since this new API is generally well supported,
  don't need to really fallback to using on scroll events
  and checking bouding rects. If this API isn't suppported in
  the browser then we simply just load all the images like normal

  NOTE: Not using the native support for the lazy loading attribute
  as well since it's not yet well supported AND for some reason
  we can't really control when the image will load unlike with
  using the IntersectionObserver API.
*/

document.addEventListener('DOMContentLoaded', lazyLoadImages);

function lazyLoadImages(event) {
  let imgs = Array.from(document.querySelectorAll('.lazy'));
  let bg_imgs = Array.from(document.querySelectorAll('.lazy-bg'));

  if ('IntersectionObserver' in window) {

    //-- <img> tags
    let io = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let img = entry.target;
          img.src = img.dataset.src;
          img.classList.remove('lazy');
          io.unobserve(img);
        }
      });
    }, { rootMargin: '0px 0px 100px 0px' });

    imgs.forEach(item => io.observe(item));

    //-- CSS background images
    let bg_io = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          let img = entry.target;
          img.classList.add('lazy-bg-visible');
          bg_io.unobserve(img);
        }
      });
    }, { rootMargin: '0px 0px 100px 0px' });

    bg_imgs.forEach(item => bg_io.observe(item));
  }
  else {
    imgs.forEach(img => img.src = img.dataset.src);
    bg_imgs.forEach(img => img.classList.add('lazy-bg-visible'));
  }
}

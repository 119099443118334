import React, { Suspense } from 'react';
import axios from 'axios';
import { render } from 'react-dom';
import { debounce } from 'lodash-es';
import Spinner from '../shared/Spinner';
import ReactTooltip from 'react-tooltip';
import ToastGlobal from '../shared/ToastGlobal';

import {
  urlValidator,
  containsWhiteSpace,
  containsSafeURLCharacters,
  parameterizeString
} from '../../utils/urlHelpers';

// Images
import AppStoreIcon from 'images/apple-app-store-icon.png';
import PlayStoreIcon from 'images/play-store-icon.png';
import CloudDownload from '../../images/illustrations/cloud-download-outline.svg'
import EnterApp from '../../images/illustrations/enter-outline.svg'
import HandLeft from '../../images/illustrations/hand-left-outline.svg'

const AppListModal = React.lazy(() => import(/* webpackChunkName: "app-list-modal" */ './AppListModal'));
const LoginModal = React.lazy(() => import(/* webpackChunkName: "login-modal" */ './LoginModalNew'));
const IosModal = React.lazy(() => import(/* webpackChunkName: "ios-modal" */ './IosModal'));
const AndroidModal = React.lazy(() => import(/* webpackChunkName: "android-modal" */ './AndroidModal'));
const UpsellModal = React.lazy(() => import(/* webpackChunkName: "upsell-modal" */ '../shared/UpsellModal'));
const SchemeInfoModal = React.lazy(() => import(/* webpackChunkName: "upsell-modal" */ '../shared/SchemeInfoModal'));

const safeInputChars = "A-Z, 0-9, ? ! \" #  $ % & ' ( ) * + , - . / \\ : ; < > =  ? @ [ ] ^ _ ` { } | ~";

export default class Composer extends React.Component {
  state = {
    url: { url: "", app:{ name: '', }, enable_instagram_camera: true, app_urls: { default_android_app_url: "", default_ios_app_url: "", android_app_url: "", ios_app_url: "", ios_store_url: "", android_store_url: "", aEnable: false, iEnable: false }, ios: { scheme: "", custom: false }, android: { scheme: "", custom: false }, include: { iphone: true, ipad: true, ipod: true, a_m: true, a_t: true }, launch: { ipod: false, iphone: false, ipad: false, a_m: false, a_t: false }, auto: { ipod: true, iphone: true, ipad: true, a_m: true, a_t: true }, short_link: "", short_link_unfurled_url: "", short_link_unfurl_failed: false, amzn_short_link_unfurl_failed: false, amazon_unfurled_url: ""},
    app_icon_url: null,
    app: '',
    errorMsg: '',
    hasAndroidDefined: false,
    hasIosDefined: false,
    isGeneralAppMap: false,
    problemWithGeneralAppMap: '',
    inputErrors: {
      hasUnsafeChars: false,
      hasSpaces: false,
      hasBadUrl: false,
      testUrlError: false,
      isUrlGeniusUrl: false,
      urlIsAmazonAco: false,
      failedCreate: false
    },
    appleStoreInputErrors: false,
    playStoreInputErrors: false,
    appleSchemeInputErrors: false,
    playSchemeInputErrors: false,
    validUrl: false,
    isStoreUrl: false,
    storeType: null,
    showIosModal: false,
    showAndroidModal: false,
    showLoginModal: false,
    showAppListModal: false,
    iosScheme: '',
    customIosScheme: '',
    iosCustom: false,
    iphoneAuto: true,
    ipadAuto: true,
    androidScheme: '',
    customAndroidScheme: '',
    androidCustom: false,
    androidPhoneAuto: true,
    androidTabletAuto: true,
    urlShortValid: true,
    pathTaken: false,
    pathCheckInProgress: false,
    currentUser: {},
    storeUrl: '',
    storeFallbackUrl: '',
    storeUrlErrors: {
      store: false,
      fallback: false
    },
    storeUrlShort: '',
    appleStoreUrl: '',
    playStoreUrl: '',
    unsupportedUrl: false,
    popularApps: {},
    appToShow: null,
    categoryToShow: null,
    showSpinner: false,
    currentCompany: {},
    smartBanner: false,
    appInstallOpen: true,
    showUpSellModal: false,
    isPro: false,
    activateAppStoreRoutingMode: false,
    activateAppDeepLinkingMode: false,
    helpFindMyAppSchemes: false,
    showSchemeInfoModal: false,
    width: window.innerWidth,
    mavelyPoweredByUrlGenius: false,
  }

  inputCursorPos = 0;
  composerInput = React.createRef();
  previousXHRCall = null;

  componentDidMount = () => {
    axios.defaults.headers.common = {
      'X-Requested-With': 'XMLHttpRequest',
      'X-CSRF-TOKEN' : document.querySelector('meta[name="csrf-token"]').getAttribute('content')
    }

    const userQuery = document.querySelector("meta[name='currentUser']")

    if (userQuery !== null){
      const currentUser = JSON.parse(userQuery.getAttribute("content"));
      this.setState({ currentUser })
    }

    axios.get("/api/internal/social_apps_for_ui")
      .then(res => {
        const apps = res.data
        this.setState({ popularApps: apps })
      })
      .catch(error => {
        console.log(error)
      })

    if (document.querySelector('#current_company') !== null) {
      const currentCompany = JSON.parse(document.querySelector('#current_company').getAttribute("data"));

      if(currentCompany) {
        this.setState({ currentCompany, isPro: currentCompany.waive_in_stripe || currentCompany.valid_credit_card });
      }
    }

    this.showAppDetailsOnLoad();
  }

  // Checks if the passed in a # (hash) in the url
  // and if so, then based on if they passed in a category
  // or an app name, we show the details for that app in the
  // <AppListModal>
  showAppDetailsOnLoad = () => {
    let url = decodeURI(window.location);

    if(url.indexOf('#') > -1) {
      let hash = url.split('#')[1];

      if(hash) {
        let set = hash.split('=');
        let [type, item] = set;

        if((type === 'app' || type === 'category') && set.length === 2) {
          if(!!item) {
            let obj = {};
            obj[type === 'category' ? 'categoryToShow' : 'appToShow'] = parameterizeString(item);
            this.setState(obj, () => this.showAppListModal());
          }
        }
      }
    }
  }

  composerOnChangeHandler = (e) => {
    let input = e.target;
    let value = input.value;
    this.validateAndCreateUrl(value);
  }

  validateInput = (value) => {
    let hasWhiteSpace = containsWhiteSpace(value);
    let hasSafeChars = containsSafeURLCharacters(value);
    let inputErrors = {...this.state.inputErrors};

    if(hasSafeChars) {
      if(hasWhiteSpace) {
        inputErrors.hasSpaces = true;
        inputErrors.hasUnsafeChars = false;
        this.setState({ inputErrors });
      }
      else {
        inputErrors.hasSpaces = false;
        inputErrors.hasUnsafeChars = false;
        this.setState({ inputErrors });

        // REVIEW: Manually setting the value of the input field to the new trimmed value
        // since we are using "defaultValue" instead of "value" (uncontrolled input) on the composer's
        // input. We can't switch it over to value yet since we'd create some bugs / issues
        // Could be because of the usage of debounce or moving the selection back to the last position on focus
        // Need to review, and note for any future update to controlled input.
        this.composerInput.current.value = value;
      }
    } else {
      inputErrors.hasUnsafeChars = true;
      this.setState({ inputErrors });
    }

    return hasSafeChars && !hasWhiteSpace;
  }

  validateStoreInput = (value, type) => {
    let hasWhiteSpace = containsWhiteSpace(value);
    let hasSafeChars = containsSafeURLCharacters(value);
    if(hasWhiteSpace || !hasSafeChars){
      type == "ios" ?
        this.setState({appleStoreInputErrors: true})
        :
        this.setState({playStoreInputErrors: true})
    }
    else{
      type == "ios" ?
        this.setState({appleStoreInputErrors: false})
        :
        this.setState({playStoreInputErrors: false})
    }
  }

  validateUserSchemeInput = (value, type) => {
    let hasWhiteSpace = containsWhiteSpace(value);
    let hasSafeChars = containsSafeURLCharacters(value);
    if(hasWhiteSpace || !hasSafeChars){
      type == "ios" ?
        this.setState({appleSchemeInputErrors: true})
        :
        this.setState({playSchemeInputErrors: true})
    }
    else{
      type == "ios" ?
        this.setState({appleSchemeInputErrors: false})
        :
        this.setState({playSchemeInputErrors: false})
    }
  }

  // NOTE: The "input" argeument is if it's from the composer UI input field
  // The "newURL" is if it's from some other location such as the AppListModal
  appListModalCreateDeeplink = (url) => {
    this.composerInput.current.focus();

    if(url) {
      this.validateAndCreateUrl(url);
    }
  }

  internalUser = () => {
    if(this.state.currentUser){
      let user = this.state.currentUser
      return(user.admin || user.safe_admin || user.solutions_engineer || user.inside_sales || user.accounting_admin)
    }
  }

  validateAndCreateUrl = debounce((input) => {
    let url = {...this.state.url};
    let urlString;

    let inputErrors = {...this.state.inputErrors};
    inputErrors.isUrlGeniusUrl = false
    inputErrors.urlIsAmazonAco = false
    inputErrors.hasBadUrl = false;
    inputErrors.failedCreate = false
    this.setState({ inputErrors });
    if(input) {
      let value = input.trim();
      if(input.includes("urlgeni.us") && !this.internalUser()){
        inputErrors.isUrlGeniusUrl = true
        this.setState({ inputErrors });
        return
      }
      if(this.urlIsUnsupportedAmazonShort(input)){
        inputErrors.urlIsAmazonAco = true
        this.setState({ inputErrors });
        return
      }

      // NOTE: If the input does not past the test we
      // cancel any on-going axios calls and exit right away
      if(!this.validateInput(value)) {
        if(this.previousXHRCall) {
          this.previousXHRCall.cancel();
        }
        this.composerInput.current.classList.remove("composer-input--loading");
        return;
      }

      urlString = this.lowerCaseHostname(value);
      url.url = urlString;
      this.inputCursorPos = this.composerInput.current.selectionStart;
    }
    else {
      return;
    }

    url.short = '';
    url.path = '';
    url.short_link_unfurl_failed = false
    this.setState({ url }, () => {
      if (!this.state.url.url) {
        this.setState({ validUrl: false })
      }
      else {
        this.composerInput.current.classList.add("composer-input--loading");

        this.createTestUrl().then(res => {
          if(res.data.error) {
            throw new Error(res.data.error);
          }

          let appMap = res.data;
          let appMapID = appMap.app_map_id;

          // NOTE: Checking if this app map is mapped to an app store
          // if so then we know it's a store URL
          // - 1196 is Apple App Store
          // - 1197 is Google Play Store
          if (appMapID === 1196 || appMapID === 1197) {
            let storeType = this.getStoreType(appMapID);
            if(storeType.main.platform === "iOS"){
              this.setState({appleStoreUrl: this.state.url.url})
            }
            if(storeType.main.platform === "Android"){
              this.setState({playStoreUrl: this.state.url.url})
            }
            let current_url = {...this.state.url}
            current_url.short = appMap.short
            this.setState({url: current_url})
            this.setState({
              validUrl: false,
              isStoreUrl: true,
              activateAppStoreRoutingMode: true,
              storeType: storeType,
            }, () => {
              this.composerInput.current.classList.remove("composer-input--loading");
            });
          }
          else {
            const validated = urlValidator(this.state.url.url);
            if(appMap.short_link != "") {
              let current_url = {...this.state.url}
              current_url.short_link_unfurled_url = appMap.short_link_unfurled_url
              current_url.short_link = appMap.short_link
              this.setState({url: current_url})
            }
            if(appMap.amazon_unfurled_url != "") {
              let current_url = {...this.state.url}
              current_url.amazon_unfurled_url = appMap.amazon_unfurled_url
              this.setState({url: current_url})
            }
            if (validated) {
              this.setState({ isStoreUrl: false, storeType: null });
              this.buildTestUrl(appMap);
            }
          }
        })
        .catch(error => {
          if(error.message && error.message.includes('valid URL')) {
            let inputErrors = {...this.state.inputErrors};
            inputErrors.hasBadUrl = true;
            this.setState({ inputErrors });
          }
          this.composerInput.current.classList.remove("composer-input--loading");
        });
      }
    });
  }, 1000);

  lowerCaseHostname(value) {
    let a = document.createElement('a');
    let protocolCheck = /^(https|http)/i;
    let hasProtocol = protocolCheck.test(value);
    let final;

    a.href = hasProtocol ? value : `//${value}`;

    let re = new RegExp(a.hostname, 'ig');

    if(hasProtocol) {
      let pre = new RegExp(a.protocol, 'ig');
      final = value.replace(pre, a.protocol);
      final = final.replace(re, a.hostname);
    } else {
      final = value.replace(re, a.hostname);
    }

    return final;
  }

  restoreInputCursorPosition = (e) => {
    e.target.selectionStart = this.inputCursorPos;
    e.target.selectionEnd = this.inputCursorPos;
  }

  getStoreType = (id) => {
    let main, alt;

    const playStore = {
      name: "Google Play Store",
      platform: "Android",
      icon: PlayStoreIcon
    };

    const appStore = {
      name: "Apple App Store",
      platform: "iOS",
      icon: AppStoreIcon
    };

    if (id === 1196) {
      main = appStore;
      alt = playStore;
    }
    else if (id === 1197) {
      main = playStore;
      alt = appStore;
    }

    return { main, alt };
  }

  createTestUrl = () => {
    let data = { url: this.state.url.url };
    this.previousXHRCall = axios.CancelToken.source();

    return axios.post(`/api/internal/test_url`, data, {
      cancelToken: this.previousXHRCall.token
    }).catch( e =>{
      console.log(e)
      let inputErrors = {...this.state.inputErrors};
      inputErrors.testUrlError = true;
      this.setState({ inputErrors });
    })
  }

  createUnfurlFailed = (url, type) => {
    let companyPresent = (Object.keys(this.state.currentCompany).length > 0)
    let companyId = companyPresent ? this.state.currentCompany.id : null
    this.previousXHRCall = axios.CancelToken.source();

    axios.post(`/api/internal/unfurl_failed`, { company_id: companyId, url: url, type: type} )
    .catch(error => {
      console.warn(error)
    })
  }

  urlIsASupportedShortLink = (url) => {
    return ["howl.me", "howl.link","go.magik.ly","mavely.app","walmartcreator.com","walmrt.us","shop-links.co", "shopstyle.it", "homedepot.sjv", "goto.target", "creatoriq.cc"].map(x => url.includes(x)).includes(true) && !url.includes("walmartcreator.com/storefront")
  }

  urlIsUnsupportedAmazonShort = (url) => {
    let urlWithoutProtocol = url.replace(/(^\w+:|^)\/\//, '');
    let splitURl = urlWithoutProtocol.split("a.co/");
    return (urlWithoutProtocol.includes("a.co/") && (splitURl[0] == "" || splitURl[0] == "www."))
  }

  buildTestUrl = (data) => {
    let url = { ...this.state.url }
    if (data.app) {
      url.app = data.app
      url.isGeneralAppMap = data.is_general_app_map
      url.problemWithGeneralAppMap = data.problem_with_general_app_map
      url.hasAndroidDefined = data.has_android_defined
      url.hasIosDefined = data.has_ios_defined
      this.setState({url})
    }
    if (data.valid) {
      url.android.scheme = data.android_scheme
      url.android.custom = (data.app.id === 10)
      url.ios.scheme = data.ios_scheme
      url.ios.custom = (data.app.id === 10)
      url.app_urls.default_ios_app_url = data.ios_app_url
      url.app_urls.default_android_app_url = data.android_app_url
      url.app_urls.ios_app_url = data.ios_app_url
      url.app_urls.android_app_url = data.android_app_url
      url.path = data.path
      url.short = data.short
      url.p = data.p
      url.app_map_id = data.app_map_id
      if(((data.app_map_id == 5) && this.urlIsASupportedShortLink(url.url) && (data.short_link_unfurled_url == ""))){
        url.short_link_unfurl_failed = true
        this.createUnfurlFailed(url.url, "")
      }
      else{
        url.short_link_unfurl_failed = false
      }
      if(url.ios.scheme.includes("ErrorPleaseTryAgain")){
        url.ios.scheme = ""
        url.ios.custom = true
        url.android.scheme = ""
        url.android.custom = true
        url.amzn_short_link_unfurl_failed = true
        url.app_map_id = 5
        this.createUnfurlFailed(url.url, "amzn")
      }
      this.setState({
        url,
        validUrl: true,
        app_icon_url: data.app_icon_url,
        iosScheme: data.ios_scheme,
        androidScheme: data.android_scheme,
        mavelyPoweredByUrlGenius: data.app_map_id == 1692
      })
    } else {
      this.setState({
        validUrl: false,
        errorMsg: data.error,
        app_icon_url: null
      })
    }
    this.composerInput.current.classList.remove("composer-input--loading")
  }

  checkPathValidityOnChange = (e, isStoreUrl=false) => {
    this.checkPathValidity(e.target, isStoreUrl);
  }

  checkPathValidity = debounce((target, isStoreUrl) => {
    this.setState({ pathTaken: false, pathCheckInProgress: true });
    let testPath;
    let shortString = target.value;
    let regex = /[^a-zA-Z0-9\;\-\:\_]/;

    if(!isStoreUrl) {
      let appPath = this.state.url.p.match(/.us\/(.*)/)[1];
      testPath = `${appPath}${shortString}`;
    }

    if (shortString.length > 3 && !regex.test(shortString)) {
      this.setState({ urlShortValid: true })

      axios.post(`/api/internal/test_path`, { path: (!isStoreUrl) ? testPath : shortString } )
        .then(res => {
          this.setState({
            validPath: !res.data.found,
            storeUrlShort: shortString,
            pathCheckInProgress: false
          });
          this.updateShortLinkAndPath(shortString, testPath);
        })
        .catch(error => {
          this.setState({pathTaken: true, pathCheckInProgress: false})
        })
    }
    else {
      this.setState({urlShortValid: false, pathCheckInProgress: false})
    }
  }, 400)

  updateShortLinkAndPath = (shortString, path) => {
    let url = { ...this.state.url };
    url.path = path;
    url.short = shortString;
    this.setState({url});
  }

//  iOS functions
  openIosModal = () => this.setState({showIosModal: true})

  updateIpad = () => {
    let newUrl = { ...this.state.url }
    newUrl.auto.ipad = !this.state.url.auto.ipad
    this.setState({ url: newUrl })
  }

  updateIphone = () => {
    let newUrl = { ...this.state.url }
    newUrl.auto.iphone = !this.state.url.auto.iphone
    this.setState({ url: newUrl })
  }

  updateIosScheme = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.ios.scheme = value
    this.setState({ url: newUrl, customIosScheme: value })
  }

  handleUserIosSchemeChange = (event) => {
    event.persist()
    const val = event.target.value
    this.validateUserSchemeInput(val, "ios")
    this.setState({ customIosScheme:  val})
    this.userUpdateIosScheme(val)
  }

  userUpdateIosScheme = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.ios.scheme = value
    this.setState({ url: newUrl, iosScheme: value })
  }

  submitIosEdit = () => {
    if ( this.state.url.ios.scheme !== this.state.iosScheme) {
      let newUrl = { ...this.state.url }
      newUrl.ios.custom = true
      this.setState({
        url: newUrl,
        showIosModal: false
       })
    } else {
      this.setState({
        showIosModal: false
      })
    }
  }

  cancelIosModal = () => {
    let newUrl = { ...this.state.url }
    newUrl.ios.scheme = this.state.iosScheme
    newUrl.ios.custom = this.state.iosCustom
    newUrl.auto.iphone = this.state.iphoneAuto
    newUrl.auto.ipad = this.state.ipadAuto
    this.setState({
      url: newUrl,
      showIosModal: false
    })
  }

// Android functions
  openAndroidModal = () => this.setState({ showAndroidModal: true })

  updateAndroidPhone = () => {
    let newUrl = { ...this.state.url }
    newUrl.auto.a_m = !this.state.url.auto.a_m
    this.setState({ url: newUrl })
  }

  updateAndroidTablet = () => {
    let newUrl = { ...this.state.url }
    newUrl.auto.a_t = !this.state.url.auto.a_t
    this.setState({ url: newUrl })
  }

  updateAndroidScheme = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.android.scheme = value
    this.setState({ url: newUrl, customAndroidScheme: value })
  }

  submitAndroidEdit = () => {
    if (this.state.url.android.scheme !== this.state.androidScheme) {
      let newUrl = { ...this.state.url }
      newUrl.android.custom = true
      this.setState({
        url: newUrl,
        showAndroidModal: false
      })
    } else {
      this.setState({
        showAndroidModal: false
      })
    }
  }

  cancelAndroidModal = () => {
    let newUrl = { ...this.state.url }
    newUrl.android.scheme = this.state.androidScheme
    newUrl.android.custom = this.state.androidCustom
    newUrl.auto.a_m = this.state.androidPhoneAuto
    newUrl.auto.a_t = this.state.androidTabletAuto

    this.setState({
      url: newUrl,
      showAndroidModal: false
    })
  }

  handleUserAndroidSchemeChange = (event) => {
    event.persist()
    const val = event.target.value
    this.validateUserSchemeInput(val, "android")
    this.setState({ customAndroidScheme: val })
    this.userUpdateAndroidScheme(val)
  }

  userUpdateAndroidScheme = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.android.scheme = value
    this.setState({ url: newUrl, androidScheme: value })
  }

  freeTrialLinkLimitCheck = () => {
    let linkLimit = 5;
    let allowedUsers = this.state.currentUser &&
        (this.state.currentUser.admin || this.state.currentUser.inside_sales || this.state.currentUser.solutions_engineer);

    if(allowedUsers) {
      return false;
    } else {
      return this.state.currentCompany.link_count >= linkLimit && !this.state.isPro;
    }
  }

  updateLink = (e) => {

    if (this.freeTrialLinkLimitCheck()) {
      this.setState({ showUpSellModal: true });
      return;
    }

    let newUrl = { ...this.state.url }
    if (this.state.iosScheme != "" && this.state.androidScheme != "")
    {this.setState({helpFindMyAppSchemes: false})}

    if (this.state.url.auto.iphone === false)
      { newUrl.launch.iphone = true }
    if (this.state.url.auto.ipad === false)
      { newUrl.launch.ipad = true }
    if (this.state.url.auto.ipod === false)
      { newUrl.launch.ipod = true }
    if (this.state.url.auto.a_m === false)
      { newUrl.launch.a_m = true }
    if (this.state.url.auto.a_t === false)
      { newUrl.launch.a_t = true }

    if (this.state.url.app_urls.ios_app_url === this.state.url.app_urls.default_ios_app_url)
      { newUrl.app_urls.ios_app_url = "" }
    if (this.state.url.app_urls.android_app_url === this.state.url.app_urls.default_android_app_url)
      { newUrl.app_urls.android_app_url = "" }

    if (Object.keys(this.state.currentUser).length !== 0) {
      this.setState({ showSpinner: true });
      this.createLink();
    } else {
      this.setState({ showLoginModal: true });
    }
  }

  validateAndCreateStoreLink = (e) => {
    const validatedInputUrl = urlValidator(this.state.url.url)
    const validatedStoreUrl = urlValidator(this.state.storeUrl)

    if(this.freeTrialLinkLimitCheck()) {
      this.setState({ showUpSellModal: true });
      return;
    }
    if (this.state.iosScheme != "" && this.state.androidScheme != "")
    {this.setState({helpFindMyAppSchemes: false})}

    if (this.oneOrMoreStoreUrls() && (validatedInputUrl || validatedStoreUrl)) {
      if (Object.keys(this.state.currentUser).length !== 0) {
        this.setState({ showSpinner: true });
        this.createStoreLink();
      } else {
        this.setState({ showLoginModal: true });
      }
    }
  }

  createLink = () => {
    let scheme_help = ((this.state.iosScheme == "" ||
                        this.state.androidScheme == "")
                        && this.state.helpFindMyAppSchemes)

    axios.post(`/api/internal/links/ui_create_social_link`, { url: this.state.url, 'help_finding_schemes': scheme_help
  })
      .then(res => {
        const link_id = res.data.id
        if(link_id) {
          window.location = `/links/${link_id}#routing`
        } else {
          let inputErrors = {...this.state.inputErrors};
          inputErrors.failedCreate = true;
          this.setState({ inputErrors });
          this.setState({ showSpinner: false });
        }
      })
      .catch(error => {
        console.log(error.response);
        this.setState({ showSpinner: false });
      })
  }

  loginModalUrl = () => {
    let url = {};

    if(this.state.storeType) {
      let store = this.state.storeType.main.platform;
      let storeParams = {
        'isStoreUrl': true,
        'fallback': this.state.storeFallbackUrl,
        'path': this.state.storeUrlShort,
        'smart_banner': this.state.smartBanner,
        'open_store_link_in_app': this.state.appInstallOpen,
        'ios': (store === 'iOS') ? this.state.url.url : this.state.storeUrl,
        'android': (store === 'iOS') ? this.state.storeUrl : this.state.url.url
      };

      url = {...this.state.url, ...storeParams};
    } else {
      url = {...this.state.url, isStoreUrl: false};
    }

    return url;
  }

  createStoreLink = () => {
    let store = this.state.storeType.main.platform;
    let checkedPath = !!this.state.storeUrlShort ? this.state.storeUrlShort : this.state.url.short
    let params = {
      'created_by': 'ui',
      'fallback': this.state.storeFallbackUrl,
      'path': checkedPath,
      'smart_banner': this.state.smartBanner,
      'open_store_link_in_app': this.state.activateAppDeepLinkingMode,
      'android_scheme' : this.state.androidScheme,
      'ios_scheme' : this.state.iosScheme,
      'help_finding_schemes': this.state.helpFindMyAppSchemes,
      'user_entered_url' : this.state.url.url
    };

    params.ios = (store === 'iOS') ? this.state.url.url : this.state.appleStoreUrl;
    params.android = (store === 'Android') ? this.state.url.url : this.state.playStoreUrl;

    axios.post(`/api/internal/links/create_appstore_link`, params)
      .then(res => {
        const link_id = res.data.id

        if(this.state.smartBanner){
          window.location = `/links/${link_id}#routing&options`;
        }
        else if (this.state.appInstallOpen) {
          window.location = `/links/${link_id}#routing`;
        }
        else {
          window.location = `/links/${link_id}`;
        }
      })
      .catch(error => {
        console.log(error.response);
        this.setState({ showSpinner: false });
      })
  }

  oneOrMoreStoreUrls = () => {
    if ((this.state.url.url.length > 4) || (this.state.storeUrl.length > 4)) {
      return true
    }
  }

  handleStoreUrl = (event) => {
    const storeUrl = event.target.value
    this.setState({ storeUrl }, () => {
      this.validateStoreUrl(storeUrl, 'store');
    })
  }
  handleAppleStoreUrl = (event) => {
    const storeUrl = event.target.value
    this.validateStoreInput(storeUrl, "ios")
    this.setState({ appleStoreUrl: storeUrl })
    this.userUpdateAppStoreUrl(storeUrl)
  }
  handlePlayStoreUrl = (event) => {
    const storeUrl = event.target.value
    this.validateStoreInput(storeUrl, "play")
    this.setState({ playStoreUrl: storeUrl })
    this.userUpdatePlayStoreUrl(storeUrl)
  }

  userUpdatePlayStoreUrl = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.android_store_url = value
    this.setState({ url: newUrl })
  }
  userUpdateAppStoreUrl = (value) => {
    let newUrl = { ...this.state.url }
    newUrl.ios_store_url = value
    this.setState({ url: newUrl })
  }


  handleStoreFallbackUrl = (event) => {
    const storeFallbackUrl = event.target.value
    this.setState({ storeFallbackUrl }, () => {
      this.validateStoreUrl(storeFallbackUrl, 'fallback');
    })
  }

  validateStoreUrl = (value, field) => {
    let errors = {...this.state.storeUrlErrors};
    errors[field] = /\s/.test(value);
    this.setState({ storeUrlErrors: errors })
  }

  disableStoreComposeBtn = () => {
    let inputErrors = this.state.storeUrlErrors.fallback || this.state.storeUrlErrors.store || this.state.appleSchemeInputErrors || this.state.appleStoreInputErrors || this.state.playSchemeInputErrors || this.state.playStoreInputErrors;
    let validPath = this.state.pathTaken || !this.state.urlShortValid;
    let noPath = this.state.url.short === '';
    return validPath || inputErrors || noPath || this.state.pathCheckInProgress;
  }

  disableComposeButton = () => {
    let hasInputErrors = this.state.inputErrors.hasUnsafeChars || this.state.inputErrors.hasSpaces || this.state.appleSchemeInputErrors || this.state.appleStoreInputErrors || this.state.playSchemeInputErrors || this.state.playStoreInputErrors || this.state.inputErrors.isUrlGeniusUrl || this.state.inputErrors.urlIsAmazonAco;
    return this.state.pathTaken || !this.state.urlShortValid || hasInputErrors || this.state.pathCheckInProgress;
  }

  closeLoginModal = (user) => {
    this.setState({
      showLoginModal: false,
      validUrl: false,
      isStoreUrl: false,
      showSpinner: user.confirmed
    });

    this.resetUrl();
  }

  showAppListModal = () => {
    this.setState({ showAppListModal: true });
  }

  closeAppListModal = () => {
    this.setState({
      showAppListModal: false,
      appToShow: null,
      categoryToShow: null
    }, () => {
      history.replaceState('', document.title, window.location.pathname + window.location.search);
    });
  }

  cancelLoginModal = () => {
    this.setState({ showLoginModal: false })
  }

  resetUrl = () => {
    this.setState({ url: { url: "", app_urls: { default_android_app_url: "", default_ios_app_url: "", android_app_url: "", ios_app_url: "", aEnable: false, iEnable: false }, ios: { scheme: "", custom: false }, android: { scheme: "", custom: false }, include: { iphone: true, ipad: true, ipod: true, a_m: true, a_t: true }, launch: { ipod: false, iphone: false, ipad: false, a_m: false, a_t: false }, auto: { ipod: true, iphone: true, ipad: true, a_m: true, a_t: true } }})
  }

  validShort = () => {
    if (!this.state.urlShortValid) {
      return false
    } else if (this.state.pathTaken) {
      return false
    } else {
      return true
    }
  }

  setCurrentUser = (user) => {
    this.setState({ currentUser: user })
    if (this.state.isStoreUrl) {
      this.createStoreLink()
    } else {
      this.createLink()
    }
  }

  seeAppDetails = (id) => {
    this.setState({ appToShow: id }, () => {
      this.showAppListModal();
    });
  }

  updateSmartBanner = (e) => {
    this.setState({ smartBanner: !this.state.smartBanner });
  }

  showSmartBannerOption = () => {
    return this.state.currentUser.admin || this.state.currentUser.inside_sales || this.state.currentUser.solutions_engineer || this.state.currentCompany.smart_banner_enabled;
  }

  hideUpsellModal = () => {
    this.setState({ showUpSellModal: false });
  }
  closeSchemeInfoModal = () => {
    this.setState({ showSchemeInfoModal: false });
  }

  customAppMap = () => {
    return this.state.url.app_map_id == 5
  }

  render() {

    let inputBoxImage;

    if (this.state.isStoreUrl) {
      inputBoxImage = <img src={this.state.storeType.main.icon} />;
    }
    else if (this.state.app_icon_url) {
      inputBoxImage = <img src={this.state.app_icon_url} className={`${this.state.app_icon_url ? '' : 'hidden'}`} />;
    }

    return (
      <Suspense fallback="">
        {this.state.showSpinner ? <Spinner /> : null}

        <ReactTooltip />

        {this.state.showIosModal &&
          <IosModal
            url={this.state.url}
            showIosModal={this.state.showIosModal}
            closeIosModal={this.cancelIosModal}
            handleIpadChange={this.updateIpad}
            handleIphoneChange={this.updateIphone}
            handleIosSchemeChange={this.updateIosScheme}
            onSubmit={this.submitIosEdit}
          />
        }

        {this.state.showAndroidModal &&
          <AndroidModal
            url={this.state.url}
            showAndroidModal={this.state.showAndroidModal}
            closeAndroidModal={this.cancelAndroidModal}
            handleAndroidTabletChange={this.updateAndroidTablet}
            handleAndroidPhoneChange={this.updateAndroidPhone}
            handleAndroidSchemeChange={this.updateAndroidScheme}
            onSubmit={this.submitAndroidEdit}
          />
        }

        {this.state.showLoginModal &&
          <LoginModal
            showLoginModal={this.state.showLoginModal}
            url={this.loginModalUrl()}
            closeLoginModal={this.closeLoginModal}
            cancelLoginModal={this.cancelLoginModal}
            setCurrentUser={this.setCurrentUser}
          />
        }

        {this.state.showUpSellModal && !this.state.isPro} {
          <UpsellModal
            isOpen={this.state.showUpSellModal}
            hideModal={this.hideUpsellModal}
            onConfirmClick={() => this.setState({ isPro: true })}
          />
        }

        {this.state.showSchemeInfoModal &&
          <SchemeInfoModal
            isOpen={this.state.showSchemeInfoModal}
            closeModal={this.closeSchemeInfoModal}
          />
        }

        {/*
          NOTE: Because we are conditionally rendering the modal here
          the react-modal's close transition will not fire. Meaning it will nto smoothly
          animate out, but rather will just close...Something for us to revisit.
          https://github.com/reactjs/react-modal/blob/master/docs/styles/transitions.md
        */}
        {this.state.showAppListModal &&
          <AppListModal
            categoryToShow={this.state.categoryToShow}
            appToShow={this.state.appToShow}
            modalIsOpen={this.state.showAppListModal}
            setComposerUrl={this.appListModalCreateDeeplink}
            onClose={this.closeAppListModal}
            ref='AppListComponent'
          />
        }


        <div className='w-11/12 m:w-1/2 mx-auto text-base mt-8 text-center opacity-100' style={{minHeight: '317px'}}>
          <div>

            {/* Composer Input */}
            <div
              id="composer"
              className="max-w-3xl m-auto"
            >
              <form className="composer-form">
                <input
                  type="url"
                  ref={this.composerInput}
                  id="url-input"
                  className="composer-input h-20"
                  placeholder='Enter a URL to get started! (For a home page, add a slash "/" after the domain)'
                  autoFocus
                  key={this.state.url.url}
                  defaultValue={this.state.url.url}
                  onChange={e => this.composerOnChangeHandler(e)}
                  onFocus={this.restoreInputCursorPosition}
                />

                {((this.state.validUrl || this.state.isStoreUrl) &&
                  !this.state.inputErrors.hasSpaces && !this.state.inputErrors.hasUnsafeChars) &&
                  <div className='composer-icon'>{inputBoxImage}</div>
                }
              </form>

              <div className="composer-error-wrapper">
                {this.state.inputErrors.hasSpaces &&
                  <p className="composer-error">
                    You have some white space in the enter URL. Please remove the white space to continue.
                  </p>
                }

                {(this.state.inputErrors.hasBadUrl || this.state.inputErrors.testUrlError) &&
                  <p className="composer-error">
                    There was an issue with the URL you entered.
                  </p>
                }
                {this.state.inputErrors.failedCreate &&
                  <p className="composer-error">
                    We encountered an error trying to make this link. Please press the "Compose" button to try again.
                  </p>
                }

                {this.state.inputErrors.hasUnsafeChars &&
                  <p className="composer-error">
                    We only accept the following {safeInputChars}
                  </p>
                }

                {this.state.url.short_link_unfurl_failed &&
                  <p className="composer-warning">
                    We were unable to extract your shortlink or we don't support the final destination yet.
                  </p>
                }
                {this.state.url.amzn_short_link_unfurl_failed &&
                  <p className="composer-warning">
                    Note: we are having difficulty optimizing this shortened URL for app deep linking. You can still create this link, but the destination app may not open as expected.
                  </p>
                }
                {this.state.inputErrors.isUrlGeniusUrl &&
                  <p className="composer-error">
                    Please enter a different destination URL. Linking to other "URLgeni.us" links is not supported
                  </p>
                }
                {this.state.inputErrors.urlIsAmazonAco &&
                  <p className="composer-error">
                    Please enter a different destination URL. Linking to this domain is not supported.
                  </p>
                }
                {this.state.mavelyPoweredByUrlGenius &&
                  <p className="composer-info">
                    Note: this URL is already optimized by URLgenius.
                  </p>
                }
              </div>
            </div>

            {/* Composer Box — Regular Links */}
            {(this.state.validUrl && !this.customAppMap()) ? (
              <div className='w-full max-w-3xl bg-white mx-auto mt-3 mb-4 p-4 sm:p-8 shadow-lg rounded-lg'>
                {/* could make ios and android its own component */}
                <div className='flex items-center h-16 border border-solid border-gray300 rounded-lg'>
                  <div className='flex flex-0 items-center justify-center' style={{'width': '64px'}}>
                    <i className='fab fa-apple fa-2x text-genius' />
                  </div>
                  <div className='flex-1 text-left text-obelisk truncate'>
                    {this.state.url.ios.scheme}
                  </div>
                  <div
                    className='cursor-pointer'
                    onClick={this.openIosModal}
                  >
                    <i className='fas fa-sliders-h ml-4 mr-4 text-blueGreen hover:text-darkBlueGreen' />
                  </div>
                </div>

                <div className='flex items-center h-16 mt-3 border border-solid border-gray300 rounded-lg'>
                  <div className='flex items-center justify-center' style={{'width': '64px'}}>
                    <i className='fab fa-android fa-2x text-genius' style={{'fontSize': '1.8rem'}} />
                  </div>
                  <div className='flex-1 text-left text-obelisk truncate'>
                    {this.state.url.android.scheme}
                  </div>
                  <div
                    className='cursor-pointer'
                    onClick={this.openAndroidModal}
                  >
                    <i className='fas fa-sliders-h ml-4 mr-4 text-blueGreen hover:text-darkBlueGreen' />
                  </div>
                </div>

                <div className='mt-6 p-4 bg-grayLight rounded-lg'>
                  <div className='w-full text-obelisk font-bold text-base text-left'>
                    Make it Unique
                  </div>

                  <div className='w-full text-obelisk text-base text-left'>
                    Customize the end of your link with a campaign name or
                    unique identifier.
                  </div>
                  <div className='flex flex-wrap w-full text-obelisk text-base text-left mt-4 pb-4'>
                    <div className='text-obelisk text-base pt-2'>
                      <img
                        src={this.state.app_icon_url}
                        className='h-6 text-center rounded mr-4'
                      />
                    </div>
                    <div className='text-obelisk text-base mr-1 pt-3'>
                      {(this.state.currentCompany && this.state.currentCompany.whitelabel_domain) ?
                        `${this.state.currentCompany.whitelabel_domain}/${this.state.url.p.match(/.us\/(.*)/)[1]}` :
                        this.state.url.p
                      }
                    </div>
                    <div className='text-obelisk text-base flex-grow'>
                      <input
                        type='text'
                        className={`input ${this.validShort() ? '' : 'invalid-input'}`}
                        placeholder='Path (required)'
                        defaultValue={this.state.url.short}
                        onChange={this.checkPathValidityOnChange}
                      />

                      {this.state.pathTaken &&
                        <p className='text-strawberry text-sm mt-2 ml-2'>This path is already taken</p>
                      }

                      {!this.state.urlShortValid &&
                        <p className='text-strawberry font-normal text-sm mt-2 ml-2'>Must be at least 4 characters. Only letters, numbers, and - : ; _</p>
                      }
                    </div>
                  </div>
                </div>
                {this.state.pathCheckInProgress ?
                  <div className="mt-6" style={{height: "45px"}}>
                    <Spinner inline/>
                  </div>
                :
                  <button
                    className={`btn mx-auto mt-5 ${this.disableComposeButton() ? 'btn--disabled' : ''}`}
                    onClick={this.updateLink}
                    disabled={this.disableComposeButton()}
                  >
                    Compose
                  </button>
                }
              </div>
            ) : null}

            {/* Composer Box — Store URLs */}
            {/* ---------Store URL's New Checkbox Flow ----------*/}
            {(this.state.isStoreUrl && !this.customAppMap()) &&
              <div className="w-full max-w-3xl bg-white mx-auto mt-3 mb-4 p-4 pt-2 sm:p-8 shadow-lg rounded-lg text-obelisk text-left">
                <div className="p-4 mb-4 bg-grayLight rounded-lg">
                <h6 className="font-bold text-obelisk">Advanced Options:</h6>
                  <div className={`${this.state.width < 430 ? null : "ml-4"}`}>

                    <label className="inline-flex items-center mb-2 mt-2">
                      <input
                        type="checkbox"
                        disabled={true}
                        checked={this.state.activateAppStoreRoutingMode}
                        id="activate-checkbox"
                        className="form-checkbox text-blueGreen"
                        style={{outline: "1px solid #1e5180"}}
                      />
                      <span className="ml-2 mr-1 text-blueGreen">Grow my app installs</span>
                      <img src={CloudDownload} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
                    </label>
                  </div>
                  <div className={`${this.state.width < 430 ? null : "ml-4"}`}>
                    <label className="inline-flex items-center mb-2">
                      <input
                        type="checkbox"
                        id="activate-checkbox"
                        className="form-checkbox text-blueGreen"
                        checked={this.state.activateAppDeepLinkingMode}
                        onChange={() => this.setState({ activateAppDeepLinkingMode: !this.state.activateAppDeepLinkingMode })}
                        style={{outline: "1px solid #1e5180"}}
                      />
                      <span className="ml-2 mr-1 text-blueGreen">Grow my app engagement</span>
                      <img src={EnterApp} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
                    </label>
                  </div>
                </div>
                {/* Create App Link */}
                <div className={`app-store-fallbacks mb-6 ${this.state.width < 430 ? null : "ml-6"}`}>
                  <div className="app-store-box">
                    <fieldset>
                      <h6 className="text-obelisk font-bold mt-1 mb-2">Enter your App Store URL</h6>

                      {(this.state.appleStoreInputErrors || this.state.playStoreInputErrors) && <p className="text-sm text-strawberry">Please remove white space / bad characters.</p> }

                      <div className="input-icon">
                        <img
                          className="input-icon__icon"
                          src={AppStoreIcon}
                          alt="iOS Icon"
                        />
                        <input
                          type='text'
                          className={`input ${null ? 'invalid-input' : ''}`}
                          value={this.state.appleStoreUrl}
                          onChange={this.handleAppleStoreUrl}
                          placeholder="ex https://apps.apple.com/app-id (optional)"
                        />
                      </div>
                    </fieldset>
                    <fieldset>
                      <div className="input-icon mt-3">
                        <img
                          className="input-icon__icon"
                          src={PlayStoreIcon}
                          alt="Play Store Icon"
                        />
                        <input
                          type='text'
                          className={`input ${null ? 'invalid-input' : ''}`}
                          value={this.state.playStoreUrl}
                          onChange={this.handlePlayStoreUrl}
                          placeholder="ex https://play.google.com/app-id (optional)"
                        />
                      </div>
                    </fieldset>
                  </div>
                </div>
                {this.state.storeUrlErrors.store &&
                  <span className="block mt-2 text-left text-sm text-strawberry">Spaces are not allowed</span>
                }

                {this.state.activateAppDeepLinkingMode &&
                <div className={`${this.state.width < 430 ? null : "ml-6"}`}>
                  <h6 className="text-obelisk font-bold mt-1 mb-2">Enter the app URL scheme you'd like to open
                  <ion-icon
                    name="help-circle-outline"
                    class={`text-blueGreen cursor-pointer ${this.state.width < 400 ? "hidden" : null}`}
                    data-tip="In order to open or deep link in your app, your app needs schemes ex. intent://url-genius/#Intent;package=com.app.deeplinking;scheme=sdkfree;end or url-genius://"
                    style={{
                      transform: 'translateY(12%) translateX(58%)'
                    }}
                    onClick={() => this.setState({ showSchemeInfoModal: true })}
                  ></ion-icon></h6>
                  {(this.state.appleSchemeInputErrors || this.state.playSchemeInputErrors) && <p className="text-sm text-strawberry">Please remove white space to continue.</p> }
                  <div className='flex items-center h-16 border border-solid border-gray300 rounded-lg scheme-input'>
                    <div className='flex flex-0 items-center justify-center' style={{'width': '64px'}}>
                      <i className='fab fa-apple fa-2x text-genius' />
                    </div>
                    <input 
                      className='flex-1 text-left text-obelisk truncate input'
                      placeholder="example://pageID"
                      value={this.state.url.customIosScheme}
                      onChange={(e) => this.handleUserIosSchemeChange(e)}
                      style={{border: '0px', padding: '1em 0em'}}
                    />
                  </div>
                  <div className='flex items-center h-16 mt-3 border border-solid border-gray300 rounded-lg scheme-input'>
                    <div className='flex items-center justify-center' style={{'width': '64px'}}>
                      <i className='fab fa-android fa-2x text-genius' style={{'fontSize': '1.8rem'}} />
                    </div>
                    <input
                      className='flex-1 text-left text-obelisk truncate input'
                      placeholder="example://pageID"
                      value={this.state.url.customAndroidScheme}
                      onChange={(e) => this.handleUserAndroidSchemeChange(e)}
                      style={{border: '0px', padding: '1em 0em'}}
                    />
                  </div>
                  {this.state.width < 400 &&
                    <div className="mt-4 ml-1">
                      <a onClick={() => this.setState({ showSchemeInfoModal: true })} className="help-link text-sm underline">What are Schemes?</a>
                    </div>
                  }
                  {(this.state.appleStoreUrl || this.state.playStoreUrl) && (this.state.customIosScheme === "" || this.state.customAndroidScheme === "") &&
                    <div className={`mt-4 bg-grayLight rounded-lg ${this.state.width < 400 ? "p-4" : "pt-4 pb-4" }`}>
                      <label className={`inline-flex items-center mb-2 mt-2 ${this.state.width < 430 ? null : "ml-6"}`}>
                        <input
                          type="checkbox"
                          checked={this.state.helpFindMyAppSchemes}
                          onChange={() => this.setState({ helpFindMyAppSchemes: !this.state.helpFindMyAppSchemes })}
                          id="activate-checkbox"
                          className="form-checkbox text-blueGreen mr-3"
                          style={{outline: "1px solid #1e5180"}}
                        />
                        <span className="text-blueGreen text-bold">Help me find my app's schemes!</span>
                        <img src={HandLeft} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.3em'}}/>
                      </label>
                    </div>
                  }
                </div>
                }

                {/* Make it Unique */}
                <div className="rounded-lg p-6 mt-6 border border-gray300 rounded-lg">
                  <h6 className="font-bold mt-0 mb-2">Make it Unique</h6>
                  <p>Customize the end of your link with a campaign name or unique identifier.</p>

                  <div className="flex flex-wrap mt-4 py-2 pl-4 pr-2 bg-grayLight rounded-lg">
                    <div className="mr-2 pt-3">
                      {(this.state.currentCompany && this.state.currentCompany.whitelabel_domain) ?
                        `${this.state.currentCompany.whitelabel_domain}/` :
                        "https://urlgeni.us/"
                      }
                    </div>
                    <div className="flex-grow">
                      <input
                        type="text"
                        className={`input ${this.validShort() ? '' : 'invalid-input'}`}
                        placeholder="Path (required)"
                        defaultValue={this.state.url.short}
                        onChange={(e) => this.checkPathValidityOnChange(e, true)}
                      />

                      {this.state.pathTaken &&
                        <p className="text-strawberry text-sm mt-2">This path is already taken</p>
                      }

                      {!this.state.urlShortValid &&
                      <p className="text-strawberry font-normal text-sm mt-2">Must be at least 4 characters. Only letters, numbers, and - : ; _</p>
                      }
                    </div>
                  </div>
                </div>

                <div className="text-center">
                  <div className={`relative ${!this.state.pathCheckInProgress && "inline-block cursor-not-allowed"} mt-6`} >
                    {this.disableStoreComposeBtn() &&
                      <>
                        <ReactTooltip />
                        <div
                          className="absolute w-full h-full z-10"
                          data-tip={this.disableStoreComposeBtn() && !this.state.pathCheckInProgress ? 'Please remove input errors' : ''}>
                        </div>
                      </>
                    }
                    {this.state.pathCheckInProgress ?
                      <div className="mt-6" style={{height: "45px"}}>
                        <Spinner inline/>
                      </div>
                    :
                      <button
                        className={`btn ${this.disableStoreComposeBtn() ? 'btn--disabled' : ''}`}
                        onClick={this.validateAndCreateStoreLink}
                        disabled={this.disableStoreComposeBtn()}
                      >
                        Compose
                      </button>
                    }
                  </div>
                </div>
              </div>
            }
            {/* --------START NEW CHECKBOX FLOW Regular URLs--------- */}
            {this.customAppMap() &&
              <div className='w-full max-w-3xl bg-white mx-auto mt-3 mb-6 p-4 sm:p-8 shadow-lg rounded-lg text-left'>
                {!this.state.url.amzn_short_link_unfurl_failed &&
                <div className="p-4 mb-4 bg-grayLight rounded-lg">
                <h6 className="font-bold text-obelisk" >Advanced Options:</h6>
                  <div className={`${this.state.width < 430 ? null : "ml-4"}`}>
                    <label className="inline-flex items-center mb-2 mt-2">
                      <input
                        type="checkbox"
                        id="activate-checkbox"
                        className="form-checkbox text-blueGreen mr-1"
                        checked={this.state.activateAppStoreRoutingMode}
                        onChange={() => this.setState({ activateAppStoreRoutingMode: !this.state.activateAppStoreRoutingMode })}
                        style={{outline: "1px solid #1e5180"}}
                      />
                      <span style={{fontSize: '15px'}} className="ml-2 mr-1 text-blueGreen">Grow my app installs</span>
                      <img src={CloudDownload} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
                    </label>
                  </div>
                  <div  className={`${this.state.width < 430 ? null : "ml-4"}`}>
                    <label className="inline-flex items-center mb-2">
                      <input
                        type="checkbox"
                        id="activate-checkbox"
                        className="form-checkbox text-blueGreen mr-1"
                        checked={this.state.activateAppDeepLinkingMode}
                        onChange={() => this.setState({ activateAppDeepLinkingMode: !this.state.activateAppDeepLinkingMode })}
                        style={{outline: "1px solid #1e5180"}}
                      />
                      <span style={{fontSize: '15px'}} className="ml-2 mr-1 text-blueGreen">Grow my app engagement</span>
                      <img src={EnterApp} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.5em'}} />
                    </label>
                  </div>
                </div>
                }
                {this.state.activateAppStoreRoutingMode && this.state.validUrl &&
                <>
                  {/* -------NEW CHECKBOX FLOW APP STORE ROUTING-------- */}
                  <div className={`app-store-fallbacks mb-6 ${this.state.width < 430 ? null : "ml-6"}`}>
                    <div className="app-store-box">
                      <fieldset>
                        <h6 className="text-obelisk font-bold mt-1 mb-2">Enter your App Store URLs</h6>
                        {(this.state.appleStoreInputErrors || this.state.playStoreInputErrors) && <p className="text-sm text-strawberry">Please remove white space / bad characters.</p> }
                        <div className="input-icon">
                          <img
                            className="input-icon__icon"
                            src={AppStoreIcon}
                            alt="iOS Icon"
                          />
                          <input
                            type='text'
                            className={`input ${null ? 'invalid-input' : ''}`}
                            value={this.state.appleStoreUrl}
                            onChange={this.handleAppleStoreUrl}
                            placeholder="ex https://apps.apple.com/app-id"
                          />
                        </div>
                      </fieldset>
                      <fieldset>
                        <div className="input-icon mt-3">
                          <img
                            className="input-icon__icon"
                            src={PlayStoreIcon}
                            alt="Play Store Icon"
                          />
                          <input
                            type='text'
                            className={`input ${null ? 'invalid-input' : ''}`}
                            value={this.state.playStoreUrl}
                            onChange={this.handlePlayStoreUrl}
                            placeholder="ex https://play.google.com/app-id"
                          />
                        </div>
                      </fieldset>
                    </div>
                  </div>
                </>
                }
                {/*-------- NEW CHECKBOX FLOW SCHEME SECTION------- */}
                {this.state.activateAppDeepLinkingMode && this.state.validUrl &&
                <div className={`${this.state.width < 430 ? null : "ml-6"}`}>
                  <h6 className="text-obelisk font-bold mt-1 mb-2">Enter the app URL scheme you'd like to open
                  <ion-icon
                    name="help-circle-outline"
                    class={`text-blueGreen cursor-pointer ${this.state.width < 400 ? "hidden" : null}`}
                    data-tip="In order to open or deep link in your app, your app needs schemes ex. intent://url-genius/#Intent;package=com.app.deeplinking;scheme=sdkfree;end or url-genius://"
                    style={{
                      transform: 'translateY(12%) translateX(58%)'
                    }}
                    onClick={() => this.setState({ showSchemeInfoModal: true })}
                  ></ion-icon></h6>
                  { (this.state.appleSchemeInputErrors || this.state.playSchemeInputErrors) && <p className="text-sm text-strawberry">Please remove white space to continue.</p> }
                  <div className='flex items-center h-16 border border-solid border-gray300 rounded-lg scheme-input'>
                    <div className='flex flex-0 items-center justify-center' style={{'width': '64px'}}>
                      <i className='fab fa-apple fa-2x text-genius' />
                    </div>
                    <input
                      className='flex-1 text-left text-obelisk truncate input'
                      placeholder="example://pageID"
                      value={this.state.url.customIosScheme}
                      onChange={(e) => this.handleUserIosSchemeChange(e)}
                      style={{border: '0px', padding: '1em 0em'}}
                    />
                  </div>
                  <div className='flex items-center h-16 mt-3 border border-solid border-gray300 rounded-lg scheme-input'>
                    <div className='flex items-center justify-center' style={{'width': '64px'}}>
                      <i className='fab fa-android fa-2x text-genius' style={{'fontSize': '1.8rem'}} />
                    </div>
                    <input
                      className='flex-1 text-left text-obelisk truncate input'
                      placeholder="example://pageID"
                      value={this.state.url.customAndroidScheme}
                      onChange={(e) => this.handleUserAndroidSchemeChange(e)}
                      style={{border: '0px', padding: '1em 0em'}}
                    />
                  </div>
                  {this.state.width < 400 &&
                    <div className="mt-4 ml-1">
                      <a onClick={() => this.setState({ showSchemeInfoModal: true })} className="help-link text-sm underline">What are Schemes?</a>
                    </div>
                  }
                  {(this.state.appleStoreUrl || this.state.playStoreUrl) && (this.state.customIosScheme === "" || this.state.customAndroidScheme === "") &&
                    <div className={`mt-4 bg-grayLight rounded-lg ${this.state.width < 400 ? "p-4" : "pt-4 pb-4"}`}>
                      <label className={`inline-flex items-center mb-2 mt-2 ${this.state.width < 430 ? null : "ml-6"}`}>
                      <input
                        type="checkbox"
                        checked={this.state.helpFindMyAppSchemes}
                        onChange={() => this.setState({ helpFindMyAppSchemes: !this.state.helpFindMyAppSchemes })}
                        id="activate-checkbox"
                        className="form-checkbox text-blueGreen mr-3"
                        style={{outline: "1px solid #1e5180"}}
                      />
                        <span className="text-blueGreen text-bold">Help me find my app's schemes!</span>
                        <img src={HandLeft} alt="" className="ml-2 text-blueGreen cursor-pointer" style={{width: '1.3em'}}/>
                      </label>
                    </div>
                  }
                  </div>
                }
                <div className='mt-6 p-4 bg-grayLight rounded-lg'>
                  <div className='w-full text-obelisk font-bold text-base text-left'>
                    Make it Unique
                  </div>

                  <div className='w-full text-obelisk text-base text-left'>
                    Customize the end of your link with a campaign name or
                    unique identifier.
                  </div>
                  <div className='flex flex-wrap w-full text-obelisk text-base text-left mt-4 pb-4'>
                    <div className='text-obelisk text-base pt-2'>
                      <img
                        src={this.state.app_icon_url}
                        className='h-6 text-center rounded mr-4'
                      />
                    </div>
                    <div className='text-obelisk text-base mr-1 pt-3'>
                      {(this.state.currentCompany && this.state.currentCompany.whitelabel_domain) ?
                        `${this.state.currentCompany.whitelabel_domain}/${this.state.url.p.match(/.us\/(.*)/)[1]}` :
                        this.state.url.p
                      }
                    </div>
                    <div className='text-obelisk text-base flex-grow'>
                      <input
                        type='text'
                        className={`input ${this.validShort() ? '' : 'invalid-input'}`}
                        placeholder='Path (required)'
                        defaultValue={this.state.url.short}
                        onChange={this.checkPathValidityOnChange}
                      />

                      {this.state.pathTaken &&
                        <p className='text-strawberry text-sm mt-2 ml-2'>This path is already taken</p>
                      }

                      {!this.state.urlShortValid &&
                        <p className='text-strawberry font-normal text-sm mt-2 ml-2'>Must be at least 4 characters. Only letters, numbers, and - : ; _</p>
                      }
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <div className={`relative ${!this.state.pathCheckInProgress && "inline-block cursor-not-allowed"} mt-6`} >
                    {this.disableComposeButton() &&
                      <>
                        <ReactTooltip />
                        <div
                          className="absolute w-full h-full z-10"
                          data-tip={this.disableComposeButton() && !this.state.pathCheckInProgress ? 'Please remove input errors' : ''}>
                        </div>
                      </>
                    }
                    {this.state.pathCheckInProgress ?
                      <div className="mt-6" style={{height: "45px"}}>
                        <Spinner inline/>
                      </div>
                    :
                      <button
                        className={`btn ${this.disableComposeButton() ? 'btn--disabled' : ''}`}
                        onClick={this.updateLink}
                        disabled={this.disableComposeButton()}
                      >
                        Compose
                      </button>
                    }
                  </div>
                </div>
              </div>
            }

            {/* Most Popular Apps Section */}
            <div id="popular-apps-tray" className="shadow-lg rounded-lg w-full max-w-3xl bg-white mx-auto mt-4 mb-4 opacity-100">
              <div className='flex flex-wrap px-2 md:px-6 pt-5 pb-4 px-5'>
                <div className='flex w-full justify-between'>
                  <div className='w-full text-obelisk font-bold text-base text-left'>
                    Most Popular Apps for Deep Linking and QR Code Generation
                  </div>
                  <a
                    className='hidden md:block flex-none cursor-pointer'
                    onClick={this.showAppListModal}
                  >
                    See more apps
                  </a>
                </div>

                <div className="popular-apps flex flex-nowrap overflow-auto mt-3">
                  {this.state.popularApps.length > 0
                    ? this.state.popularApps.map((app, index) => {
                        return (
                          <div
                            key={app.id}
                            className={`app-box flex-none mb-4 ${
                              index === 0 ? 'ml-0' : 'ml-4'
                            }`}
                          >
                            <ReactTooltip />
                            <div
                              data-tip={app.ui_hover_tooltip}
                              className='app-box__icon'
                              onClick={() =>
                                this.seeAppDetails(app.id)
                              }
                            >
                              <img src={app.icon_128} style={{'width': '67px', 'height': '67px'}}/>
                            </div>
                          </div>
                        )
                      })
                    : null}
                </div>

                <div className='md:hidden block w-full mt-5 mb-1' style={{minHeight: '24px'}}>
                  <a
                    className='flex-none cursor-pointer'
                    onClick={this.showAppListModal}
                  >
                    See more apps
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Suspense>
    )
  }
}

document.addEventListener('readystatechange', (event) => {
  if (document.readyState === "complete") {
    const container = document.querySelector('#composer-section');

    if(container) {
      render(<Composer />, container);
    }
  }
});

(function() {

  var redirects = [
      ['#/account/billing','/account/billing'],
      ['#/account/history','/account/history'],
      ['#/account/api','/account/api'],
      ['#/account/users','/account/users'],
      ['#/links','/links'],
  ]

  for (var i=0; i<redirects.length; i++) {
      if (window.location.hash === redirects[i][0]) {
        console.log("here")
        window.location.replace(redirects[i][1]);
      } else if (window.location.hash.includes("companies") && window.location.hash.includes("activity")) {
        window.location.replace('/activity')
      } else if (window.location.hash.includes("links") && window.location.hash.split("/")[2] !== undefined && (!isNaN(window.location.hash.split("/")[2]) && isFinite(window.location.hash.split("/")[2]))) {
        var route = window.location.hash.split("/")[1] + "/" + window.location.hash.split("/")[2]
        window.location.replace(route)
      }
  }
})();

import React, { Component } from 'react'
import { render } from 'react-dom'
import { toast } from 'react-toastify'
import ToastGlobal from '../shared/ToastGlobal'

export default class Messages extends Component {
  componentDidMount() {
    this.renderMessage()
  }

  renderMessage = () => {
    if (this.props.message[0][0] === 'alert') {
      toast.error(this.props.message[0][1])
    } else if (this.props.message[0][0] === 'notice') {
      toast.info(this.props.message[0][1])
    }
  }

  render() {
    return(
      <div>
      </div>
    )
  }
}

document.addEventListener('readystatechange', (event) => {
  if (document.readyState === "complete") {
    const container = document.querySelector('#flash-message-script');
    const node = document.getElementById('flash-message');
    const data = container ? JSON.parse(node.getAttribute('data')) : null;

    if (container) {
      render(<Messages message={data} />, container);
    }
  }
});


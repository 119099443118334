import React from 'react';
import { render } from 'react-dom';
import ReactTooltip from 'react-tooltip';

export default function MaintenanceBanner({ copy }) {
  function hideBanner(event) {
    let elem = event.target;
    let parent = elem.parentNode;
    parent.classList.add('banner-hide');
  }

  return (
    <>
      <ReactTooltip id="banner-close" />

      <div className="maintenance-banner">
        <div className="flex items-center text-left">
          <ion-icon name="hammer"></ion-icon>
          {copy}
        </div>
        <ion-icon
          name="close"
          onClick={hideBanner}
          data-tip="Dismiss"
          data-for="banner-close"
          data-effect="float"
        ></ion-icon>
      </div>
    </>
  )
}

document.addEventListener('readystatechange', (event) => {
  if (document.readyState === "complete") {
    const container = document.getElementById('maintenance-banner');
    const data = container ? container.getAttribute('data') : '';

    if (container) {
      render(<MaintenanceBanner copy={data} />, container);
    }
  }
});

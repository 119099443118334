/* ----------------------------------
  Navigation Scrolling

  Sets the background of the header
  once it's scrolled past a certain point
*/

window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('scroll', () => {
    let nav = document.querySelector('.nav');
    let navMobile = document.querySelector('.nav-mobile');

    nav.classList.toggle('scrolled', window.scrollY > 16);
    navMobile.classList.toggle('scrolled', window.scrollY > 16);
  });
});

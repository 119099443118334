function urlValidator(url) {
  const tempUrl = url;
  const noHost = tempUrl.replace("http://", "");
  const noSecureHost = noHost.replace("https://", "");

  let checkForParams = noSecureHost.split("/")[0];
  let checkForParams2 = checkForParams.split("?")[0];
  let rootDomain = '';

  if (noSecureHost.split("/").length > 1) {
    rootDomain = checkForParams2 + "/";
  }

  if (/^([\da-z\.-]+)\.([a-z\.]{2,24}\/)$/i.test(rootDomain) == false) {
    return false;
  } else {
    return true;
  }
}

function containsWhiteSpace(value) {
  return /\s/g.test(value);
}

function containsSafeURLCharacters(value) {
  let regex = /^[a-zA-Z0-9?!"#\$%&'()*+,-\.\/\\:;<>=\?@\[\]\^_`{}\|~\s]*$/;
  return regex.test(value);
}

function parameterizeString(str, separator) {
  return str.trim().toLowerCase().replace(/[^a-z0-9]+/g, (separator || '-')).replace(/(^-+|-+$)/g, '');
}

export {
  urlValidator,
  containsWhiteSpace,
  containsSafeURLCharacters,
  parameterizeString
};

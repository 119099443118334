// Logged In
// This are the styles and JS that is needed to load
// on only when the user is logged in.

//---------------------------
// SECTION: Stylesheet

//-- Vendor
// - Load these first so we can override them below
import 'react-date-picker/dist/DatePicker.css'
import 'react-calendar/dist/Calendar.css'
import 'rc-color-picker/dist/rc-color-picker.min.css'

//-- Elements
import '../css/elements/dropdowns.css'
import '../css/elements/tables.css'
import '../css/elements/multi_select.css'

//-- Components
import '../css/components/georouting.css'
import '../css/components/upsell.css'
import '../css/components/white_label_modal.css'
import '../css/components/white_label_sell.css'
import '../css/components/ad_tracking_inputs.css'
import '../css/components/amazon_profile_select.css'
import '../css/components/qr_code_generator.css'
import '../css/components/qr_presets_modal.css'
import '../css/components/dropzone.css'
import '../css/components/chart_filters.css'
import '../css/components/star_rating.css'
import '../css/components/react_date_picker.css'
import '../css/components/help_fab.css'
import '../css/components/smartbanner_preview.css'
import '../css/components/tag_input_field.css'

//-- Pages (Logged In)
import '../css/pages/logged_in/console.css'
import '../css/pages/logged_in/link_settings.css'
import '../css/pages/logged_in/invoice.css'
import '../css/pages/logged_in/dashboard.css'
import '../css/pages/logged_in/all_clients.css'
import '../css/pages/logged_in/settings.css'
import '../css/pages/logged_in/activity.css'
import '../css/pages/logged_in/batch_upload.css'

//---------------------------
// SECTION: Javascript

const bodyClasses = document.body.classList;

// Insights
if (bodyClasses.contains('dashboard')) {
  import(/* webpackChunkName: "Insights" */ '../components/Dashboard/Dashboard');
}

// Link Console
if (bodyClasses.contains('links')) {
  import(/* webpackChunkName: "Link-Console" */ '../components/Links/Links');
}

// Activity
if (bodyClasses.contains('activity')) {
  import(/* webpackChunkName: "Activity" */ '../components/Activity/Activity');
}

if (document.getElementById("composer-section")) {
  import(/* webpackChunkName: "Creator Quotes" */ '../components/Composer/CreatorQuotesParent');
}

// Influencer Composer
if (bodyClasses.contains('influencer_composer')) {
  import(/* webpackChunkName: "Influencer Composer" */ '../components/InfluencerComposer/InfluencerComposer');
}

// Marketplace Dashboard
if (bodyClasses.contains('commission_dashboard')) {
  import(/* webpackChunkName: "Influencer Composer" */ '../components/CommissionDashboard/CommissionDashboard');
}

// Marketplace
if (bodyClasses.contains('creator_marketplace')) {
  import(/* webpackChunkName: "Creator Marketplace" */ '../components/CreatorMarketplace/CreatorMarketplace.jsx');
}

// Settings
if (bodyClasses.contains('account')) {
  import(/* webpackChunkName: "Account-Billing" */ '../components/Account/Billing');
  import(/* webpackChunkName: "Account-History" */ '../components/Account/PaymentInfo');
  import(/* webpackChunkName: "Account-Api" */ '../components/Account/ApiInfo');
  import(/* webpackChunkName: "Account-Users" */ '../components/Account/Users');
  import(/* webpackChunkName: "Account-Url-Branding" */ '../components/Account/UrlBranding');
  import(/* webpackChunkName: "Account-Invoice" */ '../components/Account/Invoice');
  import(/* webpackChunkName: "Account-SubAccounts" */ '../components/Account/SubAccounts');
  import(/* webpackChunkName: "Account-Amazon" */ '../components/Account/Amazon');
  import(/* webpackChunkName: "Account-Amazon" */ '../components/Account/TrendingLinksSettingsPage');
  import(/* webpackChunkName: "Link Collections" */ '../components/Account/LinkCollectionsSettingsPage');
  import(/* webpackChunkName: "Account-Siri-Shortcut" */ '../components/Account/SiriShortcut');
  import(/* webpackChunkName: "Account-Bookmarklet" */ '../components/Account/Bookmarklet');
  import(/* webpackChunkName: "Account-Instant-App_Links" */ '../components/Account/InstantAppLinking');

}

// Link
if (bodyClasses.contains('links') && bodyClasses.contains('show')) {
  import(/* webpackChunkName: "Link" */ '../components/Links/Link');
  import(/* webpackChunkName: "Preview-QR" */ '../components/Links/QRCodes/PreviewQRCode');
}

if (bodyClasses.contains('batch_upload')) {
  import(/* webpackChunkName: "Batch Upload" */ '../components/BatchUpload/BatchUpload');
}

// Agencies
if (bodyClasses.contains('agencies') && bodyClasses.contains('show')) {
  import(/* webpackChunkName: "Agencies" */ '../components/Agencies/AllClients');
}

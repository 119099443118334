/* -----------------------
  Global React Toastify 
  Global settings for all Toast elements. 
  Using the "toast.configure()" option so that we dont' 
  have to manully mount <ToastContainers> into each view.

  - Just import this file into the top level React component
  - NOTE: You can override any of these settings on each individual
    toast element if needed by passing in an options hash during call.
    See their official docmentation.
*/

import React from 'react';
import { toast, Slide } from 'react-toastify';

const closeButton = <ion-icon name="close"></ion-icon>;

export default (function() {
  toast.configure({
    autoClose: 30000, // 30 secs
    closeOnClick: true,
    position: 'top-right',
    closeButton: closeButton,
    hideProgressBar: true,
    transition: Slide,
    pauseOnFocusLoss: false
  })
}());
